<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24.243"
        height="24.243"
        viewBox="0 0 24.243 24.243"
    >
        <g id="icon_pop_close" transform="translate(2.121 2.121)">
            <line
                id="Line_137"
                data-name="Line 137"
                x2="20"
                y2="20"
                fill="none"
                stroke="#fff"
                stroke-linecap="round"
                stroke-width="3"
            />
            <line
                id="Line_138"
                data-name="Line 138"
                x1="20"
                y2="20"
                fill="none"
                stroke="#fff"
                stroke-linecap="round"
                stroke-width="3"
            />
        </g>
    </svg>
</template>
