import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "24.466",
  height: "24.469",
  viewBox: "0 0 24.466 24.469"
}
const _hoisted_2 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("path", {
      id: "Fill_18",
      "data-name": "Fill 18",
      d: "M24.466,12.235A12.233,12.233,0,1,1,12.236,0a12.231,12.231,0,0,1,12.23,12.235",
      transform: "translate(0 0)",
      fill: _ctx.color
    }, null, 8, _hoisted_2)
  ]))
}