<template>
    <svg
        id="icon_lobby_3view_on"
        xmlns="http://www.w3.org/2000/svg"
        width="35"
        height="27"
        viewBox="0 0 35 27"
    >
        <path
            id="Union_9"
            data-name="Union 9"
            d="M2,27a2,2,0,0,1-2-2V22a2,2,0,0,1,2-2H33a2,2,0,0,1,2,2v3a2,2,0,0,1-2,2ZM2,17a2,2,0,0,1-2-2V12a2,2,0,0,1,2-2H33a2,2,0,0,1,2,2v3a2,2,0,0,1-2,2ZM2,7A2,2,0,0,1,0,5V2A2,2,0,0,1,2,0H33a2,2,0,0,1,2,2V5a2,2,0,0,1-2,2Z"
            fill="#5c491b"
        />
    </svg>
</template>
