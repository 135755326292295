
import { useStore } from '@/store'
import { MessageBoxActionType, MessageBoxEvent } from '@/types/MessageBox'
import { EventBuss, languageOptions } from '@/types/global'
import { Emitter, computed, defineComponent, inject, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { iconX } from '@/components/icon'
import { AppMutation } from '@/store/types'
export default defineComponent({
    components: {
        'icon-x': iconX
    },
    setup() {
        const emitter = inject('emitter') as Emitter
        const store = useStore()
        const { t, locale } = useI18n()

        const currentLang = ref('')

        const langArr = ref(languageOptions)

        onMounted(() => {
            currentLang.value = lang.value
        })

        const close = () => {
            const _event: MessageBoxEvent = {
                type: MessageBoxActionType.close,
                name: 'lang'
            }
            emitter.emit(EventBuss.DIALOG, _event)
        }

        const selectLanguage = (str: string) => {
            currentLang.value = str
            store.commit(AppMutation.SET_LANG, str)
            locale.value = str

            close()
        }

        const lang = computed(() => store.getters['lang'])

        return {
            currentLang,
            lang,
            langArr,
            selectLanguage,
            close,
            t
        }
    }
})
