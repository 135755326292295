import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "24.127",
  height: "13.563",
  viewBox: "0 0 24.127 13.563"
}
const _hoisted_2 = {
  id: "icon_pop__arrow",
  transform: "translate(2.121 2.121)"
}
const _hoisted_3 = ["stroke"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("g", _hoisted_2, [
      _createElementVNode("path", {
        id: "Path_513",
        "data-name": "Path 513",
        d: "M243.083,16.357l9.942,9.942,9.942-9.942",
        transform: "translate(-243.083 -16.357)",
        fill: "none",
        "stroke-linecap": "round",
        "stroke-linejoin": "round",
        "stroke-width": "3",
        stroke: _ctx.color
      }, null, 8, _hoisted_3)
    ])
  ]))
}