
import { useStore } from '@/store'
import { AppMutation } from '@/store/types'
import { MessageBoxActionType, MessageBoxEvent } from '@/types/MessageBox'
import { EventBuss } from '@/types/global'
import { isValidNumberAmount } from '@/utils'
import { Emitter, computed, defineComponent, inject, ref } from 'vue'
import { iconX } from '@/components/icon'
import { useI18n } from 'vue-i18n'

export default defineComponent({
    components: {
        'icon-x': iconX
    },
    setup() {
        const emitter = inject('emitter') as Emitter
        const store = useStore()
        const message = ref('')
        const chipValue = ref('')
        const { t } = useI18n()

        const close = () => {
            const _event: MessageBoxEvent = {
                type: MessageBoxActionType.close,
                name: 'edit-custom-chip'
            }
            emitter.emit(EventBuss.DIALOG, _event)
        }

        const confirm = () => {
            if (isValidNumberAmount(String(chipValue.value))) {
                const num = parseInt(String(chipValue.value))

                // set the custom chip value
                store.commit(AppMutation.SET_CUSTOM_CHIP_VAALUE, num)
                close()
                return
            }

            message.value = '无效值'
        }

        return { message, chipValue, close, confirm, t }
    }
})
