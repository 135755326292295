
import { PropType, defineComponent } from 'vue'
export default defineComponent({
    props: {
        isOn: {
            type: Boolean as PropType<boolean>,
            default: () => true
        }
    }
})
