
import {
    DialogBoxName,
    MessageBoxActionType,
    MessageBoxEvent
} from '@/types/MessageBox'
import { ACT, EventBuss, OtherApiParams } from '@/types/global'
import { Emitter, computed, defineComponent, inject } from 'vue'
import { useI18n } from 'vue-i18n'

import { useStore } from '@/store'
import { AppActions, AppMutation } from '@/store/types'
import { useRouter } from 'vue-router'
import { ROUTES } from '@/router'
export default defineComponent({
    setup() {
        const emitter = inject('emitter') as Emitter
        const store = useStore()
        const router = useRouter()
        const { t } = useI18n()

        const close = () => {
            const _event: MessageBoxEvent = {
                type: MessageBoxActionType.close,
                name: 'setting'
            }
            emitter.emit(EventBuss.DIALOG, _event)
        }

        const showDialog = (name: DialogBoxName) => {
            const _event: MessageBoxEvent = {
                type: MessageBoxActionType.open,
                name: name
            }
            emitter.emit(EventBuss.DIALOG, _event)
            close()
        }

        const exitGame = () => {
            close()
            const params = {
                ...OtherApiParams,
                username: user.value,
                act: ACT.LOG_OUT
            }

            store.commit(AppMutation.SHOW_LOADING)
            store
                .dispatch(AppActions.FETCH_DATA, params)
                .then((response: string) => {
                    store.commit(AppMutation.HIDE_LOADING)
                    if (response === 'result=ok') {
                        store.commit(AppMutation.CLEAR_ALL)
                        router.push({ name: 'select-server-line' })
                    } else {
                        emitter.emit(
                            EventBuss.TOAST_MESSAGE,
                            t('prompt.networkerror')
                        )
                    }
                })
                .catch(() => {
                    store.commit(AppMutation.HIDE_LOADING)
                    emitter.emit(
                        EventBuss.TOAST_MESSAGE,
                        t('prompt.networkerror')
                    )
                })
        }

        const visitCustomerService = () => {
            close()
            window.open(process.env.VIP_SERVICE, '_blank')
        }

        const goToRule = () => {
            close()
            window.open(
                'http://n1.bb3336.com/resource/rules/rule_home.html',
                '_blank'
            )
        }

        const user = computed(() => store.getters['user'])

        return {
            visitCustomerService,
            exitGame,
            showDialog,
            t,
            close,
            goToRule
        }
    }
})
