<template>
    <svg
        id="设置按钮"
        xmlns="http://www.w3.org/2000/svg"
        width="30.667"
        height="28.902"
        viewBox="0 0 30.667 28.902"
        class="svg"
    >
        <path
            id="设置按钮-2"
            data-name="设置按钮"
            d="M10.081,28.841A15.249,15.249,0,0,1,5.5,26.205a.99.99,0,0,1-.256-1.2,4.918,4.918,0,0,0-4.091-7.1.989.989,0,0,1-.909-.818,14.479,14.479,0,0,1,0-5.283,1,1,0,0,1,.909-.82,4.915,4.915,0,0,0,4.09-7.1.99.99,0,0,1,.252-1.2A15.234,15.234,0,0,1,10.076.061.98.98,0,0,1,10.417,0a.994.994,0,0,1,.824.44,4.921,4.921,0,0,0,4.092,2.191A4.926,4.926,0,0,0,19.429.44.987.987,0,0,1,20.593.061,15.186,15.186,0,0,1,25.175,2.7a.989.989,0,0,1,.252,1.2,4.92,4.92,0,0,0,4.09,7.1.993.993,0,0,1,.91.82,15.129,15.129,0,0,1,.24,2.639,15.3,15.3,0,0,1-.241,2.645.988.988,0,0,1-.909.817,4.923,4.923,0,0,0-4.091,7.1.987.987,0,0,1-.255,1.2,15.246,15.246,0,0,1-4.582,2.636,1,1,0,0,1-.34.059.988.988,0,0,1-.822-.438,4.921,4.921,0,0,0-8.184,0,.989.989,0,0,1-1.162.379Zm10.5-2.129a13.259,13.259,0,0,0,2.75-1.575,6.9,6.9,0,0,1,5.251-9.1,12.568,12.568,0,0,0,.1-1.587,12.062,12.062,0,0,0-.1-1.58,6.906,6.906,0,0,1-5.251-9.105,13.326,13.326,0,0,0-2.744-1.576,6.893,6.893,0,0,1-5.253,2.425,6.89,6.89,0,0,1-5.25-2.424A13.4,13.4,0,0,0,7.336,3.765,6.9,6.9,0,0,1,2.087,12.87a12.652,12.652,0,0,0-.1,1.58,12.784,12.784,0,0,0,.1,1.587,6.9,6.9,0,0,1,5.251,9.1,13.318,13.318,0,0,0,2.749,1.575,6.9,6.9,0,0,1,10.495,0ZM9.406,14.45a5.929,5.929,0,1,1,11.719,1.281,5.706,5.706,0,0,1-.163.594.992.992,0,0,1-1.883-.625c.043-.13.08-.26.111-.393a4.008,4.008,0,0,0,.089-.857,3.942,3.942,0,1,0-2.681,3.74.991.991,0,1,1,.634,1.878A5.934,5.934,0,0,1,9.406,14.45Z"
        />
    </svg>
</template>
<style lang="scss" scoped>
.svg {
    fill: #ddc28c;
}
</style>
