
import { PropType, defineComponent } from 'vue'

export default defineComponent({
    props: {
        active: {
            type: Boolean as PropType<boolean>,
            default: () => false
        }
    }
})
