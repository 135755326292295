<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14.069"
        height="31.807"
        viewBox="0 0 14.069 31.807"
    >
        <path
            id="三角形"
            d="M15.9,0l15.9,14.069H0Z"
            transform="translate(0 31.807) rotate(-90)"
            fill="#fff"
        />
    </svg>
</template>
