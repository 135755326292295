import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a485fec2"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = { class: "map" }
const _hoisted_3 = { class: "timer" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = {
  key: 0,
  class: "table-limit"
}
const _hoisted_6 = {
  key: 0,
  class: "has-value"
}
const _hoisted_7 = {
  key: 0,
  class: "has-value"
}
const _hoisted_8 = {
  key: 0,
  class: "has-value"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_road_map = _resolveComponent("road-map")!

  return (_openBlock(), _createElementBlock("div", {
    class: "mobile-item-root",
    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('enter-table', _ctx.mdata)))
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["head", { 'small-f': _ctx.lang === 'en' }])
    }, [
      _createElementVNode("div", { innerHTML: _ctx.gameTypeLabel }, null, 8, _hoisted_1),
      _createElementVNode("div", null, "F" + _toDisplayString(_ctx.mdata.tableNo), 1)
    ], 2),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_road_map, {
        "bead-row-count": 7,
        class: "road-map",
        "game-type": _ctx.mdata.gameType,
        "map-data": _ctx.mdata.result,
        onRoadMapData: _ctx.handleMapDataUpdate
      }, null, 8, ["game-type", "map-data", "onRoadMapData"]),
      _createElementVNode("div", _hoisted_3, [
        (_ctx.currentCount !== -1)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(["time", _ctx.timerColor])
            }, _toDisplayString(_ctx.currentCount), 3))
          : (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: "no-bet",
              innerHTML: _ctx.t('common.stpbet')
            }, null, 8, _hoisted_4))
      ]),
      (_ctx.activetable === _ctx.mdata.tableNo)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("div", {
              class: "limit",
              onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.playTable(0)), ["stop"]))
            }, [
              _createElementVNode("span", null, _toDisplayString(_ctx.t('common.min')) + ":", 1),
              _createElementVNode("span", null, _toDisplayString(_ctx.getMin(0)), 1),
              _createElementVNode("span", null, _toDisplayString(_ctx.t('common.max')) + ":", 1),
              _createElementVNode("span", null, _toDisplayString(_ctx.getMax(0)), 1),
              (_ctx.isValidUserInfo(0))
                ? (_openBlock(), _createElementBlock("div", _hoisted_6))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", {
              class: "limit",
              onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.playTable(1)), ["stop"]))
            }, [
              _createElementVNode("span", null, _toDisplayString(_ctx.t('common.min')) + ":", 1),
              _createElementVNode("span", null, _toDisplayString(_ctx.getMin(1)), 1),
              _createElementVNode("span", null, _toDisplayString(_ctx.t('common.max')) + ":", 1),
              _createElementVNode("span", null, _toDisplayString(_ctx.getMax(1)), 1),
              (_ctx.isValidUserInfo(1))
                ? (_openBlock(), _createElementBlock("div", _hoisted_7))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", {
              class: "limit",
              onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.playTable(2)), ["stop"]))
            }, [
              _createElementVNode("span", null, _toDisplayString(_ctx.t('common.min')) + ":", 1),
              _createElementVNode("span", null, _toDisplayString(_ctx.getMin(2)), 1),
              _createElementVNode("span", null, _toDisplayString(_ctx.t('common.max')) + ":", 1),
              _createElementVNode("span", null, _toDisplayString(_ctx.getMax(2)), 1),
              (_ctx.isValidUserInfo(2))
                ? (_openBlock(), _createElementBlock("div", _hoisted_8))
                : _createCommentVNode("", true)
            ])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}