import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "27.466",
  height: "27.469",
  viewBox: "0 0 27.466 27.469"
}
const _hoisted_2 = ["stroke"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("path", {
      id: "Stroke_16",
      "data-name": "Stroke 16",
      d: "M24.466,12.235A12.233,12.233,0,1,1,12.235,0,12.232,12.232,0,0,1,24.466,12.235Z",
      transform: "translate(1.5 1.5)",
      fill: "none",
      stroke: _ctx.color,
      "stroke-miterlimit": "10",
      "stroke-width": "3"
    }, null, 8, _hoisted_2)
  ]))
}