import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "17.801",
  height: "20.153",
  viewBox: "0 0 17.801 20.153"
}
const _hoisted_2 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("path", {
      id: "Fill_20",
      "data-name": "Fill 20",
      d: "M1.858,20.153,0,18.557,15.945,0,17.8,1.594Z",
      fill: _ctx.color
    }, null, 8, _hoisted_2)
  ]))
}