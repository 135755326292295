
import {
    computed,
    defineComponent,
    Emitter,
    inject,
    onBeforeUnmount,
    PropType,
    ref,
    TableLimit,
    watch
} from 'vue'
import roadMap from '@/components/roadmap/index.vue'
import { Desk } from '@/types/Desk'
import { useI18n } from 'vue-i18n'
import { parseRoadMap } from '../roadmap/utils'
import { RoadMapData } from '../roadmap/datamodel/RoadMapData'
import { useStore } from '@/store'
import { AppActions, AppMutation } from '@/store/types'
import { ACT, EventBuss, OtherApiParams } from '@/types/global'
import { DeskMoreInfoData } from '@/types/DeskMoreInfoData'
import { useRouter } from 'vue-router'
import { ROUTES } from '@/router'

export default defineComponent({
    emits: ['enter-table', 'end-session'],
    props: {
        activetable: {
            type: Number as PropType<number>,
            default: () => -1
        },
        mdata: {
            type: Object as PropType<Desk>,
            required: true
        },
        activeTab: {
            type: Number as PropType<number>,
            required: true
        }
    },
    components: {
        'road-map': roadMap
    },
    setup(props, { emit }) {
        const emitter = inject('emitter') as Emitter
        const router = useRouter()
        const { t } = useI18n()
        const store = useStore()
        const currentCount = ref<number>(-1)

        const mapData = ref(parseRoadMap(''))

        let countDownInterval: boolean | number | any = false

        const GameType = {
            BACCARAT: 4,
            DRAGON_TIGER: 2,
            DEAL_FIRST: 5
        }

        onBeforeUnmount(() => {
            stopCountDown()
        })

        const stopCountDown = () => {
            if (typeof countDownInterval === 'number') {
                clearInterval(countDownInterval)
                countDownInterval = false
                currentCount.value = -1
            }
        }

        const startCountDown = () => {
            if (typeof countDownInterval !== 'number') {
                currentCount.value = props.mdata.currentTime

                countDownInterval = setInterval(() => {
                    if (currentCount.value < 0) {
                        stopCountDown()
                    } else {
                        currentCount.value -= 1
                    }
                }, 1000)
            }
        }

        const playTable = (i: number) => {
            if (i + 1 > limits.value.length) return

            const _limit = limits.value[i]

            // call selet limit api and eter table
            store.commit(AppMutation.SHOW_LOADING)
            const _params = {
                ...OtherApiParams,
                xianId: _limit.Id,
                desk: props.mdata.tableNo,
                act: ACT.GET_NOTICE_AND_LIMIT
            }

            store
                .dispatch(AppActions.FETCH_DATA, _params)
                .then((response: string) => {
                    store.commit(AppMutation.HIDE_LOADING)
                    if (response.includes('err=')) emit('end-session')
                    else {
                        const { limit, chipsVallue, notice } =
                            DeskMoreInfoData.Parse(response)
                        if (limit) {
                            limit.Id = _limit.Id
                            store.commit(AppMutation.SET_LIMIT, limit)
                        }

                        if (chipsVallue)
                            store.commit(AppMutation.SET_CHIPS, chipsVallue)

                        if (notice)
                            store.commit(AppMutation.SET_IN_GAME_NOTICE, notice)

                        router.push({
                            name: ROUTES.GAME_DEFAULT,
                            query: {
                                desk: props.mdata.tableNo,
                                gameType: props.activeTab,
                                xian: limit?.Id || 0
                            }
                        })
                    }
                })
                .catch(() => {
                    store.commit(AppMutation.HIDE_LOADING)
                    emitter.emit(
                        EventBuss.TOAST_MESSAGE,
                        t('prompt.networkerror')
                    )
                })
        }

        const isValidUserInfo = (_index: number): boolean => {
            return limits.value.length >= _index + 1
        }

        const getMin = (i: number) => {
            if (limits.value.length >= i + 1) return limits.value[i].Min

            return 0
        }

        const getMax = (i: number) => {
            if (limits.value.length >= i + 1) return limits.value[i].Max

            return 0
        }

        const gameTypeLabel = computed(() => {
            if (props.activeTab === GameType.BACCARAT) return t('baccarat.name')
            else if (props.activeTab === GameType.DRAGON_TIGER)
                return t('dragontiger.name')
            else
                return props.mdata.gameType === 1
                    ? t('baccarat.name')
                    : t('dragontiger.name')
        })

        const handleMapDataUpdate = (
            a: RoadMapData,
            b: RoadMapData,
            c: RoadMapData
        ) => {
            mapData.value = a
        }

        const timerColor = computed((): string => {
            if (currentCount.value > 20) return 'green'
            else if (currentCount.value <= 20 && currentCount.value > 10)
                return 'orange'
            else if (currentCount.value <= 10 && currentCount.value !== -1)
                return 'red'
            return ''
        })

        const limits = computed((): TableLimit[] => store.getters['limits'])

        const lang = computed(() => store.getters['lang'])
        watch(
            () => props.mdata,
            (v) => {
                if (v.currentTime !== -1) {
                    startCountDown()
                } else {
                    stopCountDown()
                }
            },
            { immediate: true }
        )

        return {
            isValidUserInfo,
            handleMapDataUpdate,
            mapData,
            timerColor,
            currentCount,
            gameTypeLabel,
            limits,
            playTable,
            t,
            getMin,
            getMax,
            lang
        }
    }
})
