
import { useStore } from '@/store'
import { computed, defineComponent } from 'vue'

export default defineComponent({
    setup() {
        const store = useStore()

        const isMobile = computed(() => store.getters['isMobile'])

        const deviceOrientation = computed(
            () => store.getters['deviceOrientation']
        )

        return {
            isMobile,
            deviceOrientation
        }
    }
})
