<template>
    <svg
        id="game_homeBtn_icon"
        xmlns="http://www.w3.org/2000/svg"
        width="34"
        height="30"
        viewBox="0 0 34 30"
    >
        <path
            id="game_homeBtn_icon-2"
            data-name="game_homeBtn_icon"
            d="M21.086,30a1.039,1.039,0,1,1,0-2.076h5.437a1.381,1.381,0,0,0,1.305-1.317V18.038A1.029,1.029,0,0,1,28.916,17a1.112,1.112,0,0,1,.788.3,1,1,0,0,1,.3.734v8.569A3.439,3.439,0,0,1,26.523,30ZM7.41,30a3.388,3.388,0,0,1-3.487-3.271v-11.7L1.878,16.958a1.3,1.3,0,0,1-.79.292A1.323,1.323,0,0,1,.345,17,2.331,2.331,0,0,1,0,16.052a4.348,4.348,0,0,1,.349-.763l15.86-15a1.216,1.216,0,0,1,1.582,0l15.859,15a4.187,4.187,0,0,1,.35.763,1.094,1.094,0,0,1-.3.7,1.214,1.214,0,0,1-1.585,0L17,2.66,6.317,12.76v13.8a1.357,1.357,0,0,0,1.31,1.267h4.362V21.4a1.011,1.011,0,0,1,1.088-1h7.846a1,1,0,1,1,0,2H14.164V29a1.011,1.011,0,0,1-1.087,1Z"
            fill="#5c491b"
        />
    </svg>
</template>
