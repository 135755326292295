
import { useStore } from '@/store'
import { MessageBoxActionType, MessageBoxEvent } from '@/types/MessageBox'
import { ACT, EventBuss, OtherApiParams } from '@/types/global'

import { Emitter, computed, defineComponent, inject, onMounted, ref } from 'vue'
import { iconArrowDown, iconX } from '@/components/icon'
import { useI18n } from 'vue-i18n'
import { AppActions, AppMutation } from '@/store/types'
import { BetLog } from '@/types/BetLog'
import { BetLogDeatails } from '@/types/BetLogDetails'
import { getDeviceOrientation } from '@/utils'
export default defineComponent({
    components: {
        'arrow-down': iconArrowDown,
        'icon-x': iconX
    },
    setup() {
        const emitter = inject('emitter') as Emitter
        const store = useStore()
        const { t } = useI18n()

        const betLogs = ref<Array<BetLog>>([])
        const betLogDetails = ref<Array<BetLogDeatails>>([])

        const showDetails = ref(false)

        const showLandscape = ref(true)

        let isLoading = false

        const dateValue = ref({ start: new Date(), end: new Date() })
        const masks = ref({
            input: 'YYYY-MM-DD'
        })

        onMounted(() => {
            showLandscape.value = getDeviceOrientation() === 'landscape'
        })

        // set default start time and end time

        const modelConfig = ref({
            start: {
                timeAdjust: '00:00:00'
            },
            end: {
                timeAdjust: '23:59:59'
            }
        })

        const minDate = () => {
            const days = 45
            const date = new Date()
            const last = new Date(date.getTime() - days * 24 * 60 * 60 * 1000)
            const day = last.getDate()
            const month = last.getMonth() + 1
            const year = last.getFullYear()
            return new Date(`${month}/${day}/${year}`)
        }

        const roundFixed2 = (_value = 0) => {
            return +_value.toFixed(2)
        }

        const viewBetHistory = () => {
            if (isLoading || showDetails.value) return

            if (!dateValue.value.start || !dateValue.value.end) {
                emitter.emit(EventBuss.TOAST_MESSAGE, t('datesinvalid'))
                return
            }

            const _param = {
                ...OtherApiParams,
                act: ACT.GET_LOG,
                username: user.value,
                sday: dateValue.value.start.toISOString().substr(0, 10),
                eday: dateValue.value.end.toISOString().substr(0, 10)
            }

            isLoading = true
            store.commit(AppMutation.SHOW_LOADING)
            store
                .dispatch(AppActions.FETCH_DATA, _param)
                .then((_data: string) => {
                    store.commit(AppMutation.HIDE_LOADING)
                    isLoading = false
                    if (!_data.includes('err=')) {
                        betLogs.value = BetLog.ParseList(_data)
                    }
                })
                .catch(() => {
                    isLoading = false
                    store.commit(AppMutation.HIDE_LOADING)
                    emitter.emit(
                        EventBuss.TOAST_MESSAGE,
                        t('prompt.networkerror')
                    )
                })
        }

        const OnClickLogsItem = (s: string) => {
            showDetails.value = true

            const _param = {
                ...OtherApiParams,
                act: ACT.GET_LOG_DETAILS,
                sday: s,
                username: user.value
            }

            isLoading = true
            store.commit(AppMutation.SHOW_LOADING)
            store
                .dispatch(AppActions.FETCH_DATA, _param)
                .then((_data: string) => {
                    store.commit(AppMutation.HIDE_LOADING)
                    if (!_data.includes('err=')) {
                        betLogDetails.value = BetLogDeatails.ParseList(_data)
                    }

                    isLoading = false
                })
                .catch(() => {
                    isLoading = false
                    store.commit(AppMutation.HIDE_LOADING)
                })
        }

        const close = () => {
            if (showDetails.value) {
                showDetails.value = false
                return
            }

            const _event: MessageBoxEvent = {
                type: MessageBoxActionType.close,
                name: 'bet-history'
            }
            emitter.emit(EventBuss.DIALOG, _event)
        }

        const user = computed(() => store.getters['user'])
        const lang = computed(() => store.getters['lang'])
        const xmRate = computed((): number => store.getters['xmRate'])

        return {
            xmRate,
            showLandscape,
            modelConfig,
            masks,
            dateValue,
            lang,
            betLogs,
            showDetails,
            betLogDetails,
            OnClickLogsItem,
            roundFixed2,
            viewBetHistory,
            minDate,
            close,
            t
        }
    }
})
