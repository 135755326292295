import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-41ad92db"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "setting-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "setting-root",
    onClick: _cache[6] || (_cache[6] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)), ["self"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("ul", null, [
        _createElementVNode("li", {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showDialog('lang')))
        }, _toDisplayString(_ctx.t('menu.language')), 1),
        _createElementVNode("li", {
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showDialog('bet-history')))
        }, _toDisplayString(_ctx.t('menu.accounthistory')), 1),
        _createElementVNode("li", {
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.visitCustomerService && _ctx.visitCustomerService(...args)))
        }, _toDisplayString(_ctx.t('menu.cutomerservice')), 1),
        _createElementVNode("li", {
          onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.goToRule && _ctx.goToRule(...args)))
        }, _toDisplayString(_ctx.t('menu.gamerules')), 1),
        _createElementVNode("li", {
          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.showDialog('edit-password')))
        }, _toDisplayString(_ctx.t('menu.changepwd')), 1),
        _createElementVNode("li", {
          onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.exitGame && _ctx.exitGame(...args)))
        }, _toDisplayString(_ctx.t('menu.quit')), 1)
      ])
    ])
  ]))
}