
import { defineComponent, PropType } from 'vue'

export default defineComponent({
    props: {
        color: {
            type: String as PropType<string>,
            default: () => '#5a3e43'
        }
    }
})
