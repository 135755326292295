import request from '@/utils/request'
import axios, { AxiosPromise, CancelToken, CancelTokenSource } from 'axios'

export const AxiosSource = { Source: axios.CancelToken.source() }
/**
 * Api Call
 * @param params
 * @returns
 */
export function fetch(
    params: any,
    cancelToken?: CancelTokenSource
): AxiosPromise {
    const token = cancelToken?.token ?? AxiosSource.Source.token
    return request({
        method: 'POST',
        params,
        cancelToken: token,
        responseType: 'arraybuffer'
    })
}

export function pingServer(line: number, url: string): AxiosPromise {
    let api = ''
    const endPointPath = process.env.API_EXT

    if (line === -1) {
        api = `${url}/${endPointPath}`
    } else if (line === 1) {
        api = `${process.env.API_URL_LINE1}/${endPointPath}`
    } else if (line === 2) {
        api = `${process.env.API_URL_LINE2}/${endPointPath}`
    } else if (line === 3) {
        api = `${process.env.API_URL_LINE3}/${endPointPath}`
    }

    const params = {
        skey: process.env.API_SKEY,
        act: 0,
        jm: 1
    }

    return request({
        baseURL: api,
        method: 'GET',
        params,
        responseType: 'arraybuffer'
    })
}
